<template>
  <div>
    <div v-if="currentCreative.media_type == 'video'">
      <div>
        <div>
          <div
            class="adCon"
            v-if="isFbPageAd || edit"
          >
            <upload-video
              ref="uploadVideo"
              from="fb"
              :accountId="accountId"
              :rowThirdNum="rowThirdNum"
              :adsMsg="currentCreative"
              @setVideoUrl="setVideoUrl"
              @setAdsMsg="setAdsMsg"
              @creatSlide="creatSlide"
              @deleImg="deleImg"
            ></upload-video>
            <div
              class="imgForm"
              v-if="!isFbPageAd"
            >
              <copywriter
                :creative="currentCreative"
                @setCreativeMsg="setCreativeMsg"
              ></copywriter>
            </div>
          </div>
          <div
            class="imgMsg"
            v-else
          >
            <div
              class="imgCon"
              v-if="imgList.length < canUploadNum"
            >
              <el-button
                size="small"
                @click="selectImg('video', 30)"
                >从创意云选择</el-button
              >
              <el-button
                size="small"
                @click="creatSlide"
                >创建幻灯</el-button
              >
              <gm-upload-material
                ref="uploadMaterial"
                v-model:fileList="fileList"
                :saveAdFileParams="saveAdFileParam"
                :on-success="onSuccess"
                :before-upload="beforeUpload"
              >
                <el-button
                  size="small"
                  type="primary"
                  @click="uploadTypes = 'video'"
                  >点击上传</el-button
                >
                <div
                  slot="tip"
                  class="el-upload__tip"
                >
                  <img-tips></img-tips>
                </div>
              </gm-upload-material>
            </div>
            <div class="imgList">
              <!-- {{imgList}} -->
              <video-item
                :class="['imgCon', index == activeIndex ? 'active' : '']"
                v-for="(img, index) in imgList"
                :key="`${img.id}`"
                @changeImg="() => changeImg(index)"
                :videoMsg="img"
                @deleImg="() => deleImg(index)"
                @setThumbList="setThumbList"
                @setVideoItem="(v) => setVideoItem(v, index)"
                :active="index == activeIndex"
                :index="index"
              >
                <!-- <i class="el-icon-circle-close delete" @click.stop="deleImg(index)"></i>
                                <video :src="img.url" controls></video> -->
              </video-item>
            </div>
          </div>
        </div>
        <div
          class="imgForm"
          v-if="!(isFbPageAd || edit)"
        >
          <copywriter
            v-if="imgList.length"
            :creative="currentImgMsg"
            @setCreativeMsg="setImgMsg"
          ></copywriter>
          <span
            v-else
            style="color: #f00; font-size: 12px"
            >请先上传素材再填写文案哦~~</span
          >
        </div>
      </div>
    </div>
    <div class="flex">
      <span>视频封面图：</span>
      <div class="mr-3">
        <el-button
          size="small"
          @click="selectImg('img', 1)"
          :disabled="!currentImg.source_id && !currentImg.videoStatus != 'ready'"
          >从创意云选择</el-button
        >
      </div>
      <!-- <el-upload
      class="upload-demo"
      action="#"
      :http-request="uploadVideoImg"
      :show-file-list="false"
      :before-upload="beforeUpload"
      :disabled="!currentImg.source_id"
      style="display: inline-block; margin-left: 20px"
    >
      <el-button
        size="small"
        type="primary"
        @click="uploadTypes = 'img'"
        :disabled="!currentImg.source_id && !currentImg.videoStatus != 'ready'"
        >点击上传</el-button
      >
    </el-upload> -->
      <gm-upload-material
        ref="uploadMaterial"
        v-model:fileList="fileList"
        :disabled="!currentImg.source_id"
        :saveAdFileParams="saveAdFileParam"
        :on-success="onSuccess"
        :before-upload="beforeUpload"
      >
        <el-button
          size="small"
          type="primary"
          class="inline-block ml-3"
          @click="uploadTypes = 'img'"
          :disabled="!currentImg.source_id && !currentImg.videoStatus != 'ready'"
          >点击上传</el-button
        >
      </gm-upload-material>
    </div>

    <div
      class="adCon"
      style="margin-top: 10px"
    >
      <div
        class="thumbList"
        v-if="thumbList.length"
      >
        <div
          class="thumbWrap"
          v-for="(img, thumbIndex) in thumbList"
          :key="thumbIndex"
          @click="setVideoThumb(img.uri, thumbIndex)"
        >
          <el-image
            :src="img.uri"
            fit="contain"
          ></el-image>
          <i
            class="el-icon-circle-check"
            v-if="img.isPreferred"
          ></i>
        </div>
      </div>
      <div
        class="empty"
        v-else
        style="width: 100%"
      >
        <span>
          <i class="iconfont icon-wushuju"></i>
          <p>暂无数据</p>
        </span>
      </div>
    </div>
    <img-list
      :adSeriesInfo="adSeriesInfo"
      :imgListShow="imgListShow"
      :saveAdFileParam="saveAdFileParam"
      @close="imgListShow = false"
      @urlList="urlList"
      :uploadTypes="uploadTypes"
      :limit="limit"
    ></img-list>
    <creat-slide
      :adSlideShow="adSlideShow"
      :saveAdFileParam="saveAdFileParam"
      @close="adSlideShow = false"
      @getVideoFile="getVideoFile"
    ></creat-slide>
  </div>
</template>
<script>
import tools from '../tools';
import adsMethods from '../../adsMethods';
import imgList from '@/views/adManagement/createAd/components/imgList.vue';
import imgTips from './imgTips.vue';
import copywriter from './copywriter.vue';
import uploadVideo from '../uploadVideo.vue';
import videoItem from './videoItem.vue';
import creatSlide from '../creatSlide.vue';
import { saveVideoThumb } from '@/api/creatAd.js';

export default {
  mixins: [tools, adsMethods],
  props: {
    adSeriesInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    currentCreative: {
      type: Object,
      default: () => {},
    },
    isFbPageAd: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    rowThirdNum: {
      type: String | Number,
      default: '',
    },
    accountId: {
      type: String | Number,
      default: '',
    },
  },
  components: {
    imgList,
    imgTips,
    copywriter,
    uploadVideo,
    videoItem,
    creatSlide,
  },
  data() {
    return {
      index: 0,
      imgListShow: false,
      activeIndex: 0,
      uploadTypes: 'video',
      thumbListMap: {},
      adSlideShow: false,
      uplaodType: 'video',
      defaultCopywriter: { copywriter: { title: '', content: '' }, message: '' },
      sourceMsg: {
        uploadNums: 0,
        videoStatus: null,
      },
      limit: 1,
    };
  },
  computed: {
    canUploadNum() {
      return 30;
    },
    currentImgMsg() {
      return this.imgList[this.activeIndex]?.copywriter
        ? { copywriter: this.imgList[this.activeIndex].copywriter, message: this.imgList[this.activeIndex].message }
        : { copywriter: { title: '', content: '' }, message: '' } || this.defaultCopywriter;
    },
    imgList: {
      get() {
        return this.currentCreative.imgList || [];
      },
      set(v) {
        this.setCreativeMsg(v);
      },
    },
    currentImg() {
      return this.imgList[this.activeIndex] || {};
    },
    thumbList() {
      if (this.edit || this.isFbPageAd) {
        return this.thumbListMap[this.sourceMsg.source_id] || [];
      } else {
        return this.thumbListMap[this.imgList[this.activeIndex]?.source_id] || [];
      }
    },
  },
  watch: {
    sourceMsg: {
      handler() {
        this.$emit('setCreativeMsg', { ...this.currentCreative, ...this.sourceMsg });
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    /* 文件上传成功返回数据 */
    onSuccess(response, file) {
      console.log(response, file, 'onSuccess');
      if (response?.status == 'error') {
        this.$notify.error({
          title: '错误',
          message: `${response.name}上传失败`,
          duration: 0,
        });
        return;
      }
      if (this.uploadTypes === 'img') {
        const imgData = this.edit || this.isFbPageAd ? this.sourceMsg : this.currentImg;
        const videoFileId = imgData.source_id;
        const params = {
          videoFileId: videoFileId,
          videoThumbUrl: response.url,
        };
        this.uploadThumb(params, 'bendi');
        return;
      }
      console.log(this.edit, this.isFbPageAd, 'this.edit || this.isFbPageAd');
      if (this.edit || this.isFbPageAd) {
        this.uploadVideoFb(response.id, response.sourceType);
      } else {
        response.videoStatus = 'processing';
        response.source_id = response.id;
        this.imgList = this.imgList.concat({ ...response }).slice(0, 30);
        console.log(this.imgList, 'this.imgList');
      }
    },
    // 接收上传视频的信息（编辑）
    setAdsMsg(index, msg, isupload) {
      let message = { ...this.currentCreative, ...msg };
      this.thumbListMap[msg.source_id] = msg.thumbList;
      this.sourceMsg = { ...this.sourceMsg, ...msg };
      // this.setCreativeMsg(message)
      // this.setVideoThumb(msg.thumbList[0].uri, 0, 0, isupload);
    },
    // 保存视频封面
    uploadThumb(formData, type, acIndex) {
      this.$showLoading();
      const imgData = this.edit || this.isFbPageAd ? this.sourceMsg : this.currentImg;
      console.log(imgData,'imgData');
      
      const sourceType =  imgData.sourceType;
      const videoFileId = imgData.source_id;
      const params = {
        ...formData,
        sourceType:formData.sourceType || sourceType,
        uid: this.userInfo.id,
        orgCode: this.userInfo.orgCode,
        accountId: this.$store.getters.accountId,
      };
      // let activeIndex = acIndex || acIndex === 0 ? acIndex : this.activeIndex;
      saveVideoThumb(params).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: '上传成功',
          });
          const thumbUrl = res.data.thumbUrl;
          
          // this.imgList[this.activeIndex].thumbUrl = res.data.thumbUrl;
          if (this.edit || this.isFbPageAd) {
            this.$set(this.sourceMsg, 'thumbUrl', thumbUrl);
            this.setCreativeMsg(this.sourceMsg);
          } else {
            let index = this.imgList.findIndex((v) => v.source_id == formData.videoFileId && !v.thumbUrl);
            index = index === -1 ? this.activeIndex : index;
            this.$set(this.imgList[index], 'thumbUrl', thumbUrl);
            this.setCreativeMsg(this.imgList);
          }
          console.log(this.edit || this.isFbPageAd,this.activeIndex,index,videoFileId,this.imgList,'saveVideoThumb');
          if (type && type == 'bendi') {
            let obj = {
              isPreferred: true,
              uri: thumbUrl,
            };
            let key = this.edit || this.isFbPageAd ? this.sourceMsg.source_id : this.currentImg.source_id;
            this.thumbListMap[key].map((v) => (v.isPreferred = false));
            this.thumbListMap[key].unshift(obj);
          }
          this.$forceUpdate();
        }
      }).finally(() => {
        this.$hideLoading();
      })
    },
    // 点击封面设置封面
    setVideoThumb(url, index ) {
      const imgData = this.edit || this.isFbPageAd ? this.sourceMsg : this.currentImg;
      const key = imgData.source_id;
      if (index || index === 0) {
        this.thumbListMap[key].map((v, ind) => {
          if (index == ind) {
            this.$set(this.thumbListMap[key][ind], 'isPreferred', true);
          } else {
            this.$set(this.thumbListMap[key][ind], 'isPreferred', false);
          }
        });
      }
      const params = {
        videoFileId: key,
        videoThumbUrl: url,
      };
      this.uploadThumb(params, null, this.activeIndex);
    },
    setThumbList(list, id, sourceType) {
      console.log('aaaaa', list, id, sourceType);
      this.$set(this.thumbListMap, id, list);
      let uri = list.find((v) => v.isPreferred).uri;
      const params = {
        videoFileId: id,
        videoThumbUrl: uri,
        sourceType
      };
      this.uploadThumb(params, null);
      // this.thumbListMap[id] = list
    },
    // 创建幻灯广告
    creatSlide() {
      this.adSlideShow = true;
    },
    // 接收幻灯片视频（创建的时候）
    getVideoFile(file) {
      this.adSlideShow = false;
      this.imgList = this.imgList.concat(file).slice(0, 30);
      if(this.isFbPageAd || this.edit){
        this.$refs.uploadVideo.onSuccess(file);
      }
    },
    // 接收幻灯片地址(编辑的时候)
    setVideoUrl(v, thumbList, index) {
      console.log('setVideoUrl',v, thumbList, index);
      this.adSlideShow = false;
      if (this.currentCreative.media_type != 'video') return false;
      let message = { ...this.currentCreative, image: v.url, source_id: v.id,sourceType:v.mediaSourceType, videoStatus: 'processing' };
      this.setCreativeMsg(message);
      this.thumbListMap[v.id] = thumbList;
      // this.setVideoThumb(thumbList.filter((v) => v.isPreferred)[0].uri, i);
      const url = thumbList.filter((v) => v.isPreferred)[0].uri;
      const params = {
        videoFileId: v.id,
        videoThumbUrl: url,
      };
      this.uploadThumb(params);
    },
    // 点击素材库上传
    selectImg(type, num) {
      this.uploadTypes = type;
      this.limit = num || 1;
      if (this.edit || this.isFbPageAd) {
        this.limit = 1;
      }
      this.imgListShow = true;
    },
    // 修改创意
    setCreativeMsg(v) {
      if (this.isFbPageAd || this.edit) {
        this.$emit('setCreativeMsg', { ...this.currentCreative, ...v });
      } else {
        this.$emit('setCreativeMsg', { ...this.currentCreative, imgList: v });
      }
    },
    setVideoItem(v, index) {
      this.imgList[index] = { ...this.imgList[index], ...v };
      this.setCreativeMsg(this.imgList);
    },
    // 修改文案
    setImgMsg(v, key) {
      this.$set(this.imgList, this.activeIndex, { ...this.imgList[this.activeIndex], ...v });
      // 第一次填写，其他方案默认一致
      // this.imgList.forEach((item, index) => {
      //     console.log(item);
      //     if (index == this.activeIndex) return;
      //     if(key == 'title' || key == 'content'){
      //         if(!item.copywriter){
      //             this.$set(item,'copywriter',{ title: '', content: '' })
      //             if(!item.copywriter[key]) item.copywriter[key] =  v.copywriter[key]
      //         }else{
      //             if(!item.copywriter[key]) item.copywriter[key] =  v.copywriter[key]
      //         }
      //     }else{
      //         if(!item[key]) item[key] = v[key]
      //     }
      // })
    },
    // 获取素材库图片
    urlList(v,sourceType) {
      if (this.isFbPageAd || this.edit) {
        if (this.uplaodTypes == 'video') {
          this.uploadVideoFb(v[v.length - 1].id,sourceType);
        } else {
          this.$emit('setCreativeMsg', {
            ...this.currentCreative,
            image: v[v.length - 1].url,
            source_id: v[v.length - 1].id,
          });
        }
      } else {
        if (this.uploadTypes == 'video') {
          this.imgList = this.imgList
            .concat(
              v.map((m) => {
                return { ...m, source_id: m.id, videoStatus: 'processing'};
              }),
            )
            .slice(0, 30);
        } else {
          let img = v[v.length - 1];
          let obj = {
            isPreferred: true,
            uri: img.url,
          };
          // console.log(obj);
          this.thumbListMap[this.currentImg.source_id].unshift(obj);
          this.setVideoThumb(img.url, 0 );
        }
      }
    },
    // 设置文案
    setCopyWriter(index) {
      // let defaultCopywriter = { copywriter: { title: this.defaultTilte, content: this.defaultContent }, message: this.defaultMessage }
      // this.imgList[index] = { ...this.imgList[index], ...defaultCopywriter }
      let data = this.imgList[index];
      if (!data.copywriter) {
        this.$set(data, 'copywriter', {
          title: this.defaultTitle(this.imgList),
          content: this.defaultContent(this.imgList),
        });
      } else {
        if (!data.copywriter['title']) data.copywriter['title'] = this.defaultTitle(this.imgList);
        if (!data.copywriter['content']) data.copywriter['content'] = this.defaultContent(this.imgList);
      }
      if (!data.message) data.message = this.defaultMessage(this.imgList);
    },
    // 点击选择图片切换广告语
    changeImg(index) {
      console.log(index);
      this.setCopyWriter(index);
      this.activeIndex = index;
      this.$emit('setPreviewIndex', this.activeIndex);
    },
    // 点击删除图片
    deleImg(index) {
      if (this.isFbPageAd || this.edit) {
        this.$emit('setCreativeMsg', { ...this.currentCreative, image: '' });
      } else {
        this.imgList.splice(index, 1);
        this.changeImg(0);
        this.setCreativeMsg(this.imgList);
      }
    },
    // 上传图片
    // upload_img(file) {
    //   this.uploadImg(file)
    //     .then((data) => {
    //       if (data.status == 'error') {
    //         this.$notify.error({
    //           title: '错误',
    //           message: `${file.file.name}上传失败`,
    //           duration: 0,
    //         });
    //       } else {
    //         console.log(this.edit, this.isFbPageAd, 'this.edit || this.isFbPageAd');
    //         if (this.edit || this.isFbPageAd) {
    //           this.uploadVideoFb(data.id, 'SELF_MATERIAL');
    //         } else {
    //           data.videoStatus = 'processing';
    //           data.source_id = data.id;
    //           this.imgList = this.imgList.concat({ ...data, videoStatus: 'processing' }).slice(0, 30);
    //         }
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
  },
};
</script>
<style lang="scss" scoped>
.imgCon {
  width: 220px;
  height: 220px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 15px;
  margin-right: 8px;
  // overflow: hidden;
  position: relative;
  margin: 5px;

  video {
    width: 100%;
    height: 100%;
  }

  .delete {
    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 2;
    font-size: 18px;
    cursor: pointer;
    background: #fff;
    border-radius: 50%;
  }
}

.imgMsg {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  .imgList {
    flex: 1;
    overflow-x: auto;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow-y: hidden;

    .imgCon {
      flex-shrink: 0;
      border: 3px solid transparent;
      &.active {
        border-color: #409eff;
      }
    }
  }
}

.imgForm {
  .el-form-item {
    margin-bottom: 10px;
  }
}
</style>
